.testimonials {
  text-align: center;
}

.splide--testimonials {
  max-width: 97rem;
  margin: 0 auto;
}

.testimonials__item {
  position: relative;
  margin: 0;
  font-size: 4.3rem;
  padding: 6rem;
  font-weight: 300;
  line-height: 1.2;
  text-align: left;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 6rem;
    height: 5.5rem;
    background: transparent url(../../../dist/img/quote.svg) 50% 50% / contain no-repeat;
    // transform: translate(-100%, -100%);
  }
  figure {
    margin: 0;
  }
  blockquote {
    margin: 0;
  }
  p:first-child {
    margin: 0;
  }
}

.testimonials__author {
  display: block;
  margin-top: 3rem;
  font-size: 2rem;
  font-weight: 300;
}