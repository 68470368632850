.accordion-item {
  margin-top: 2rem;
  background-color: #fff;
  border: 0.1rem solid rgba(0,0,0,.125);
}

.accordion-header {
  margin: 0;
}

.accordion-button{
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.6rem 2rem;
  font-family: $font;
  font-size: 1.7rem;
  font-weight: 500;
  color: #444;
  text-align: left;
  background-color: #fff;
  border: 0;
  cursor: pointer;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
  &::after {
    content: "";
    flex-shrink: 0;
    width: 2rem;
    height: 2rem;
    margin-left: auto;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23444'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 2rem;
    transition: transform .2s ease-in-out;
  }

  &:not(.collapsed) {
    color: #444;
    background-color: #fff;
  }

  &:not(.collapsed)::after {
    transform: rotate(-180deg);
  }
}

.collapse:not(.show) {
  display: none;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-body {
  padding: 1.6rem 2rem;
}