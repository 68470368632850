.section__title--slider {
  position: relative;
  font-size: 4rem;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    top: 84%;
    left: 50%;
    width: 100%;
    height: 80%;
    background-image: url(../../dist/img/slider_title-background.png);
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center top;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  @media screen and (min-width: 62rem) {
    font-size: 11rem;
  }
}

.splide--default {
  .slide__image {
    height: 21.8rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.splide__slide {
  background: transparent;
  max-width: 100%;
  
}

.slider__text, .slider__tools {
  @media screen and (max-width: 47.9375rem) {
    padding-right: 0;
  }
}

.slider__text {
  margin-bottom: 3.125rem;

  @media screen and (min-width: 48rem) {
    margin-bottom: 3.75rem;
  }
}

.splide__pagination {
  position: static;
}

.splide__pagination__page {
  opacity: 1;
  &.is-active {
    background-color: $primary;
  }
}

.slider__tools { 
  margin-top: 2rem;
  @media screen and (min-width: 48rem) {
    margin-top: 4rem;
  }

  &, .content-container {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
    width: auto;

    @media screen and (min-width: 48rem) {
      flex-direction: row;
      width: 100%;
    }
  }

  .slider__progress {
    background-color: $border;
    display: none;
    flex-grow: 1;
    height: 1px;
    position: relative;

    @media screen and (min-width: 48rem) {
      display: block;
    }

    .slider__progress-bar {
      background-color: $secondary;
      height: 2px;
      left: 0;
      position: absolute;
      top: -0.5px;
      transition: all 0.3s ease;
    }
  }
}

.splide__arrows {
  align-self: flex-end;
  display: flex;
  gap: .75rem;

  &.slider__arrows-desktop:not(.slider__arrows-mobile) {
    @media screen and (max-width: 47.9375rem) {
      display: none;
    }
  }

  &.slider__arrows-mobile:not(.slider__arrows-desktop) {
    @media screen and (min-width: 48rem) {
      display: none;
    }
  }

  .splide__arrow {
    background-color: $white;
    border: 1px solid $black;
    border-radius: 50%;
    height: 3rem;
    opacity: 1;
    position: static;
    transform: none;
    width: 3rem;
    transition: all 0.3s;

    @media screen and (min-width: 48rem) {
      height: 4rem;
      width: 4rem;
    }

    &:hover:not(&:disabled) {
      background-color: $secondary;
      border-color: $secondary;
      svg {
        fill: $white;
      }
    }

    &:disabled {
      border-color: $black;

      svg {
        fill: $black;
      }
    }

    svg {
      height: .625rem;
      transition: fill 0.3s;

      @media screen and (min-width: 48rem) {
        height: .75rem;
      }
    }
  }
}