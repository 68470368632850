.footer {
  font-size: 1.6rem;
  background-color: $black;

  @media screen and (max-width: 47.9375rem) {
    padding-bottom: 140px;
  }

  &,
  a {
      color: $white;
  }
}

.footer__title {
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  &::before {
    top: -25%;
    height: 200%;
  }
  @media screen and (min-width: 62rem) {
    font-size: 6rem;
  }
  @media screen and (min-width: 80rem) {
    font-size: 8rem;
  }
}

.footer__form {
  margin-bottom: 4rem;
  @media screen and (min-width: 62rem) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8rem;
  }
}

.footer__infos {
  padding-top: 3rem;
  @media screen and (min-width: 48rem) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-template-areas: 
      'locations locations locations'
      'email . socials';
    gap: 3rem;
    align-items: center;
    padding-top: 3.2rem;
    border-top: 1px solid $white;
  }
  @media screen and (min-width: 62rem) {
    grid-template-columns: fit-content(75%) minmax(min-content,auto) min-content;
    grid-template-areas: 
      'locations email socials';
  }
}

.email {
  grid-area: email;
  justify-self: end;
  font-size: 1.6rem;
}

.locations {
  font-size: 1.4rem;
  line-height: 1.2;
  @media screen and (min-width: 48rem) {
    grid-area: locations;
    display: flex;
    gap: 7rem;
  }
  @media screen and (min-width: 62rem) {
  }
}

.social__items {
  @media screen and (min-width: 48rem) {
    grid-area: socials;
    justify-self: end;
  }
}

.location__item {
  margin: 1rem 0;
  padding: 0;
  @media screen and (min-width: 48rem) {
    margin: 0;
  }
}

.location__title {
  text-transform: uppercase;
  margin: 0;
}

.location__address {
  margin: 0.5rem 0 0;
  a {
    display: block;
    text-decoration: none;
  }
}

.footer__bottom {
  font-size: 1.5rem;
  padding: 1.6rem 2rem;
  p {
    margin: 0;
  }
}