body {
  font-size: 1.5rem;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 300;
  line-height: 1.06;
  letter-spacing: -0.01em;
  text-transform: none;
  &:first-child  {
    margin-top: 0;
  }
}

h4,
.h4 {
  text-transform: uppercase;
  letter-spacing: 0.1;
}

strong {
  font-weight: 500;
}

.btn {
  display: inline-block;
  padding: 1.3rem 3rem;
  border: 0;
  border-radius: 5rem;
  font-family: $font;
  font-size: 1.4rem;
  font-weight: 200;
  line-height: 1.1;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  color: $white;
  span {
    display: block;
    font-size: 1rem;
    font-weight: 200;
    letter-spacing: normal;
  }
}

.btn--primary {
  background: transparent linear-gradient(30deg, #F7A823 0%, #E63312 100%) 0% 0% no-repeat;
}

.btn--secondary {
  background: transparent linear-gradient(30deg, hsla(183, 99%, 37%, 1) 0%, hsla(195, 83%, 39%, 1) 21%, hsla(210, 70%, 41%, 1) 47%, hsla(214, 57%, 43%, 1) 53%, hsla(222, 45%, 46%, 1) 60%, hsla(257, 30%, 48%, 1) 81%, hsla(298, 34%, 45%, 1) 100%) 0% 0% no-repeat;
}

.btn--icon {
  &::before {
    content: '';
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 1rem;
    vertical-align: sub;
    background: transparent url(../../../dist/img/btn-chat.svg) center center / contain no-repeat;

    .fixed__bottom & {
      display: none;
    }
  }
}

ol {
  counter-reset: ol-counter;
  li {
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
    margin-top: 1.7rem;
    list-style-type: none;
    counter-increment: ol-counter;
    &::before {
      content: counter(ol-counter);
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      font-size: 1.1rem;
      border-radius: 50%;
      background: transparent linear-gradient(30deg, #F7A823 0%, #E63312 100%) 0% 0% no-repeat;
    }
  }
}

.btn--big {
  font-size: 1.9rem;
}

.section__title {
  text-align: left;
  @media screen and (min-width: 62rem) {
    font-size: 4.5rem;
  }
}

.two-columns__title {
  font-size: 3.7rem;
  @media screen and (min-width: 62rem) {
    font-size: 5.7rem;
  }
}

.two-columns {
  align-items: start;
  @media screen and (min-width: 48rem) {
    grid-template-columns: repeat(2, fit-content(50%));
  }
  @media screen and (min-width: 62rem) {
    gap: 7.5rem;
  }
}

.two-columns--text {
  @media screen and (min-width: 48rem) {
    grid-template-columns: fit-content(40%) fit-content(60%);
  }
}

.column {
  @media screen and (min-width: 62rem) {
    padding: 0;
  }
}

.image-list__item {
  display: flex;
  align-items: flex-start;
  gap: 2.5rem;
  text-align: left;
  &:first-child {
    position: relative;
    left: -1.3rem;
  }
}

.image-list__title {
  margin-bottom: 0;
  font-size: 2.3rem;
  font-weight: 500;
}

.image-list__text {

}

.splide--products {
  text-align: center;
  .slide__subtitle {
    font-size: 2.3rem;
    font-weight: 500;
  }

  .slide__description {
    font-size: 1.5rem;
    font-weight: 200;
    strong {
      display: block;
      font-size: 2rem;
      font-weight: 400;
    }
  }
}

.two-columns--image {
  align-items: stretch;
  .two-columns__content {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  @media screen and (min-width: 62rem) {
    .two-columns__content {
      padding-top: 8.5rem;
      padding-bottom: 8.5rem;
      padding-left: 0;
    }
  };
}

.asterisk {
  display: block;
  font-size: 0.9rem;
}

.slide__subtitle {
  font-size: 2.3rem;
  font-weight: 500;
  line-height: 1.2;
}

.slide__description {
  font-weight: 300;
}

.footer__infos {
  border-top: 0;
  align-items: end;
  @media screen and (min-width: 62rem) {
    grid-template-areas: "locations socials ."
  }
}

.infos {
  grid-area: locations;
  .h3 {
    font-size: 2.3rem;
    font-weight: 400;
  }
}

.social__items {
  @media screen and (min-width: 48rem) {
    justify-self: start;
  }
}