// Colors
$green: #BCCF02;
$purple: #944B96;
$cyan: #01B2BB;
$blue: #206AB2;
$white: #fff;
$black: #161615;
$grey: #707070;

$primary: #EE6A1A;
$secondary: $black;
$border: #E7E9EB;

// Fonts
$font: "plain", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

:root {
  --primary: #{$primary};
}