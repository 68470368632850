.form_container {
  font-size: 1.7rem;
  a {
    color: $white;
  }
  .form_info--title {
    @media screen and (min-width: 48rem) {
      margin-top: 0.5rem;
    }
  }
  .form_info--subtitle {
    margin-top: 3rem;
  }
  .form_info--link {
    display: table;
  }
  button[type=submit] {
    align-items: center;
  }
  form {
    input:not([type=checkbox]):not([type=radio]),
    select,
    textarea {
      border: 0.0625rem solid rgba(255, 255, 255, .15);
      width: 100%;
      color: $white;
      margin-top: 3rem;
      padding: 1rem;
      background: rgba(255, 255, 255, .1);
      position: relative;
      transition: .3s;
      &::placeholder {
        color: $white;
      }
      &:focus {
        outline: 0;
      }
      &:focus ~ label,
      &:valid ~ label,
      &:not(:placeholder-shown):not(:focus):invalid  ~ label {
        top: 1rem;
        left: 0;
        font-size: 1.2rem;
        transform: translateY(0);
      } 
    }
    select {
      option {
        color: $white;
      }
    }
    label {
      transition: .3s;
      &:not(.label__agree) {
        position: absolute;
        top: 90%;
        left: 1rem;
        color: $white;
        transition: .3s;
        transform: translateY(-100%);
        span {
            color: $white;
        }
      }
    }
    input {
      &:focus,
      &:focus-visible {
        outline: none;
      }
    }
    .fcf-form-group {
      position: relative;
      p {
        font-size: 1.2rem;
      }
    }
    input::placeholder,
    select {
      opacity: .5;
    }
    input[type=checkbox]  {
      // appearance: none;
      // position: relative;
      // border: 0;
      // width: 1.5rem;
      // height: 1.5rem;
      // border: 0.1rem solid $primary;
      // margin: 0;
      // transition: .3s;
      // &:hover {
      //   cursor: pointer;
      // }
      // &::after {
      //   content: "";
      //   position: absolute;
      //   width: 1.5rem;
      //   height: 1.5rem;
      //   border: 0.1rem solid $primary;
      //   transition: .3s;
      // }
      // &:checked {
      //   background: $primary;
      // }
    }
    .checkbox-container {
      margin-top: 3rem;
      margin-bottom: 3rem;
      & > div {
        display: flex;
      }
    }
  }
  .require-label {
    color: $white;
    margin-left: 1.5rem;
    font-size: 1.4rem;
  }
  .fcf-form-group label {
    color: $white;
  }
  .label__agree {
    font-size: 1.4rem;
    margin-left: 0.5rem;
  } 
  .contact__form {
    display: block;
    align-items: unset;
      @media screen and (min-width: 48rem) {
        display: flex;
        grid-gap: 5rem;
      }
  }
  .infos_contact {
    grid-template-columns: repeat(2, auto);
    @media screen and (min-width: 48rem) {
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-gap: 1.5rem;
    }
    ul,
    p {
      margin-top: 0;
    }
    li {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 0.5rem;
    }
    ul {
      grid-column: 1/3;
      @media screen and (min-width: 48rem) {
        grid-column: 1;
      }
    }
    p {
      grid-column: 1;
      @media screen and (min-width: 48rem) {
        grid-column: 2;
      }
    }
    div {
      grid-column: 2;
      @media screen and (min-width: 48rem) {
        grid-column: 3;
      }
    }
  }
  .wrapper__form_info {
    @media screen and (min-width: 48rem) {
      width: 40%;
    }
  }
  .social-links-container {
    display: flex;
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
  .social-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.7rem;
    height: 4.7rem;
    font-size: 1.8rem;
    text-decoration: none;
    border-radius: 50%;
    border: 0.1rem solid $black;
    @media screen and (min-width: 48rem) {
      .social-icon {
        font-size: 2.3rem;
      } 
    } 
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.form_container--sent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}