.header {
  position: relative;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: $white;
  overflow: hidden;
  a {
    color: $white;
    text-decoration: none;
  }
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: transparentize(#0D0802, 0.45);
    z-index: -1;
  }
  .container {
    padding-top: 0;
    padding-bottom: 0;
  }
  @media screen and (min-width: 62rem) {
    padding-bottom: 4.8rem;
  }
}

.page__nav {
  position: relative;
  padding-bottom: 2.5rem;
  @media screen and (min-width: 48rem) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0;
    font-size: 1.5rem;
    font-weight: 300;
    &::after {
      content: none;
    }
  }
  @media screen and (min-width: 62rem) {  
    font-size: 1.6rem;
  }

  &.fixed__bottom {
    align-items: center;
    background-color: $secondary;
    border-radius: 100px;
    bottom: 0;
    column-gap: 20px;
    display: flex;
    left: 50%;
    opacity: 0;
    padding: 10px;
    position: fixed;
    transform: translateX(-50%);
    transition: .3s;
    z-index: 999;

    &.is-display {
      bottom: 92px;
      opacity: 1;
    }

    .menu__item {
      margin: 0;
    }
  }
}

.page__logo {
  display: block;
  max-height: 5.7rem;
  margin: 0 auto;
  @media screen and (min-width: 48rem) {
    margin: 0;
  }
  @media screen and (min-width: 80rem) {
    max-height: 7.6rem;
    max-width: 60rem;
  }
}

.page__menu {
  display: flex;
  align-items: center;
  gap: 1.5rem;

  header & {
    display: none;

    @media screen and (min-width: 48rem) {
      display: flex;
      gap: 3.5rem;
    }
  }

  .fixed__bottom & a {
    color: $white;
    text-decoration: none;
  }
}

.page__title {
  margin-top: 6rem;
  margin-bottom: 1rem;
  font-size: 3.6rem;
  font-weight: 300;
  line-height: 0.9;
  @media screen and (min-width: 30rem) {
    font-size: 4.4rem;
  }
  @media screen and (min-width: 48rem) {
    margin-top: 8rem;
    font-size: 5rem;
  }
  @media screen and (min-width: 62rem) {
    margin-top: 9.6rem;
    font-size: 6.6rem;
    line-height: 1.06;
  }
}

.page__intro {
  display: none; 
  @media screen and (min-width: 48rem) {
    display: block;
    margin: 1rem 0;
    font-size: 2.6rem;
    line-height: 1.5;
    max-width: 79rem;
  }
}

.header__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}

.discover {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 40rem;
  margin: 6rem auto 0;
  text-align: center;
  img {
    display: inline-block;
    margin-top: 0.5rem;
  }
  @media screen and (min-width: 62rem) {
    margin-top: 8rem;
  }
}