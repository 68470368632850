* {
  &, &:before, &:after {
    box-sizing: border-box;
  }
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: $font;
  font-size: 1.8rem;
  line-height: 1.5;
  padding: 0;
  margin: 0;
  accent-color: $primary;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 900;
  line-height: 1.1;
  text-transform: uppercase;
  &:first-child  {
    margin-top: 0;
  }
}

h2,
.h2 {
  font-size: 3.5rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

ul {
  list-style-type: ">";
  li {
    margin-top: 1rem;
    margin-left: -0.5rem;
    padding-left: 0.5rem;
  }
}

.text--white {
  color: $white !important
}

.text--primary {
  color: $primary !important
}

.text--secondary {
  color: $secondary !important
}

.text--grey {
  color: $grey !important
}

.text--black {
  color: $black !important
}

.text--center {
  text-align: center !important;
}

.bg--primary {
  color: $white;
  background-color: $primary;
}

.bg--black {
  color: $white;
  background-color: $black;
}

.container {
  max-width: 124rem;
  margin: 0 auto;
  padding: 5rem 0;
  @media screen and (min-width: 48rem) {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  @media screen and (min-width: 62rem) {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
}

.section {
  padding-left: 2rem;
  padding-right: 2rem;
}

.section__title {
  margin: 0 0 4rem;
  font-size: 4rem; 
  text-align: center;
  @media screen and (min-width: 48rem) {
    font-size: 8rem;
  }
  @media screen and (min-width: 62rem) {
    font-size: 12rem;
  }
}

.section__subtitle {
  margin-top: 0;
  font-size: 2.4rem;
  font-weight: 900;
}

.img--responsive {
  max-width: 100%;
  height: auto;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.list--reset {
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    margin-left: 0;
  }
}

.social__items {
  @extend .list--reset;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  @media screen and (min-width: 48rem) {
    margin-top: 0;
  }
}

.social__item {
  display: block;
  margin: 0;
  padding: 0;
}

.social__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.2rem;
  height: 4.2rem;
  font-size: 3.2rem;
  color: $white;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid $white;
  transition: 
    color 0.2s,
    background-color 0.2s,
    border-color 0.2s;
  &:where(:focus, :hover, :touch) {
    color: #fff;
    background-color: $primary;
    border-color: transparent;
  }
}

.social__icon {
  width: auto;
  height: 1.6rem;
  fill: $white;
}

.faq {
  text-align: left;
}

.faq__button {
  font-size: 1.9rem;
  font-weight: 900;
  line-height: 1.1;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.d--desktop {
  @media screen and (max-width: 48rem) {
    display: none !important;
  }
}

.d--mobile {
  @media screen and (min-width: 48rem) {
    display: none !important;
  }
}

.grecaptcha-badge { 
  visibility: hidden;
}