.two-columns {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: 
    'left'
    'right';
  justify-items: center;
  align-items: center;
  @media screen and (min-width: 48rem) {
    grid-template-columns: repeat(2, 50%);
    grid-template-areas: 
      'left right';
  }
}

.two-columns__content {
  padding-top: 2rem;
  padding-bottom: 2rem;
  @media screen and (min-width: 48rem) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.two-columns--image {
  .two-columns__content {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (min-width: 48rem) {
    .two-columns__content {
      padding: 2rem 5rem;
    }
  }
  @media screen and (min-width: 62rem) {
    .two-columns__content {
      padding-left: 8rem;
      padding-right: 15%;
    }
  }
}

.column {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  &:nth-child(2n+1) {
    grid-area: left;
  }
  &:nth-child(2n) {
    grid-area: right;
  }
}

.two-columns__title {
  font-size: 8rem;
  @media screen and (min-width: 62rem) {
    font-size: 13rem;
    margin: 0;
  }
}

.two-columns__image {
  @media screen and (min-width: 48rem) {
    height: 100%;
    max-height: 50vw;
    padding: 0;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.two-columns__quote {
  align-self: start;
}